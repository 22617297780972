import me from "../images/me3.jpeg"
import "./Header.scss"
import { useTranslation } from 'react-i18next'

export default function Header() {
	const { t, i18n } = useTranslation();

	return <div className={ "container" }>
		<div className={ "header-actions" }>
			<LanguageSelector />
		</div>

		<section className={ "header" }>
			<div className={ "image-wrapper" } style={ { position: "relative" } }>
				<img alt={ "An image of myself." } className={ "blur-in iosRounded" } id={ "image-of-myself" }
					 src={ me }
					 style={ { aspectRatio: "1/1", height: "250px", zIndex: "1", perspective: "10px" } }/>
				<div className={ "blur-in iosRounded" }
					 style={ {
						 aspectRatio: "1/1",
						 height: "250px",
						 position: "absolute",
						 zIndex: "0",
						 left: "calc(50% - 105px)",
						 top: "20px",
						 background: "rgba(255, 255, 255, 0.05)"
					 } }/>
			</div>
			
			<div className={ "fade-in" }>
				<p>{t('components.header.title')}</p>
				<p className={ "gradient-text" }>{t('components.header.name')}</p>
				<code className={ "typed-text" }>I'm <span className={ "title-typer" }></span></code>
			</div>
		</section>
	</div>
}

function LanguageSelector() {
	const { t, i18n } = useTranslation();

	// <button onClick={() => i18n.changeLanguage("en")} disabled={i18n.language === "en"}>en</button>
	// <button onClick={() => i18n.changeLanguage("de")} disabled={i18n.language === "de"}>de</button>

	return <select defaultValue={ i18n.language } onChange={ (e) => i18n.changeLanguage(e.currentTarget.value) }>
		<option value={ "en" }>English</option>
		<option value={ "de" }>Deutsch</option>
	</select>
}
