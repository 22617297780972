import { useTranslation } from "react-i18next"
import "./Social.scss"

export default function Social() {
	const { t } = useTranslation();

	return <div>
		<h2 className={ "fade-in" } style={ { marginBlock: 0 } }>{t('components.social.title')}</h2>
		<p className={ "fade-in" } style={ { marginBlock: "var(--spacing)" } }>{t('components.social.caption')}</p>

		<div className={ "social-grid" }>
			<a className={ "link social-link fade-in" }
				href={ "https://www.linkedin.com/in/benjaminloidl/" }
				target={ "_blank" }
				rel="noreferrer">
				<i className={ "fa-brands fa-xl fa-linkedin" }></i>
			</a>
			<a className={ "link social-link fade-in" }
				href={ "https://github.com/altetaube" }
				target={ "_blank" }
				rel="noreferrer">
				<i className={ "fa-brands fa-xl fa-github" }></i>
			</a>
			<a className={ "link social-link fade-in" }
				href={ "https://youtube.com/@altetaube" }
				target={ "_blank" }
				rel="noreferrer">
				<i className={ "fa-brands fa-xl fa-youtube" }></i>
			</a>
			<a className={ "link social-link fade-in" }
				href={ "mailto:mail@loidlnetz.de" }>
				<i className={ "fas fa-xl fa-envelope" }></i>
			</a>
		</div>
	</div>
}
