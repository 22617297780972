import { Component } from "react"
import "./Timeline.scss"
import simplechat from "../images/simplechat.png"
import fftw from "../images/projects/44tw/44tw.png"
import bigdatadashboard from "../images/bigdatadashboard.png"
import fantasyrpgGameworld from "../images/projects/fantasyrpg/gameworld.png"
import fantasyrpgInventory from "../images/projects/fantasyrpg/inventory.png"
import fantasyrpgCombatstate from "../images/projects/fantasyrpg/combatstate.png"
import simpleqQuestion from "../images/projects/simpleq/question.png"
import Section from "../components/Section"
import { useTranslation } from "react-i18next"
import sanitizeHtml from 'sanitize-html';

const halfPointHeight = 30

export default class Timeline extends Component<any, any> {
	static updateTimeline = () => {
		// @ts-ignore
		let height = window.scrollY + window.innerHeight * 0.8 - document.querySelector(".timeline")?.offsetTop
		
		if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
			height = document.body.offsetHeight
		} else if (window.scrollY === 0) {
			height = 0
		}
		
		// @ts-ignore
		document.querySelector(".timeline")?.style.setProperty("--timeline-height", `${ height }px`)
		
		document.querySelectorAll(".timeline-item").forEach((item: any) => {
			if (item.offsetTop - halfPointHeight < height) {
				item.classList.add("timeline-item-active")
			} else {
				item.classList.remove("timeline-item-active")
			}
		})
	}
	
	componentDidMount() {
		Timeline.updateTimeline()
	}
	
	render() {
		return <Projects />
	}
}

function Projects() {
	const { t } = useTranslation();

	return <Section title={ t('components.projects.title') } caption={ t('components.projects.caption') }>
		<div className={ "timeline" }>
			<TimelineItem id={ "project-44tw" } year={ 2025 }
						  caption={ t('components.projects.projects.44tw.caption') } title={ t('components.projects.projects.44tw.title') }
						  urls={ [ { url: "https://bigdatadashboard.loidlnetz.de/", label: "Demo" } ] }
						  images={ [
							<img src={ fftw } alt={ "44tw Screenshot" } />
						  ] }
						  hashtags={ t('components.projects.projects.44tw.hashtags', { returnObjects: true }) as string[] }>
				<div dangerouslySetInnerHTML={{ __html: sanitizeHtml(t('components.projects.projects.44tw.html')) }} />
			</TimelineItem>

			<TimelineItem id={ "project-covid-tracker" } year={ 2024 }
						  caption={ t('components.projects.projects.covidTracker.caption') } title={ t('components.projects.projects.covidTracker.title') }
						  urls={ [ { url: "https://bigdatadashboard.loidlnetz.de/", label: "Demo" } ] }
						  images={ [
							<img src={ bigdatadashboard } alt={ "Big Data Dashboard" } />
						  ] }
						  hashtags={ t('components.projects.projects.covidTracker.hashtags', { returnObjects: true }) as string[] }>
				<div dangerouslySetInnerHTML={{ __html: sanitizeHtml(t('components.projects.projects.covidTracker.html')) }} />
			</TimelineItem>
			
			<TimelineItem id={ "project-fantasyrpg" } year={ 2024 }
						  caption={ t('components.projects.projects.fantasyRPG.caption') } title={ t('components.projects.projects.fantasyRPG.title') }
						  urls={ [ { url: "https://github.com/benniloidl/FantasyRPG", label: "Repository" } ] }
						  images={ [
							<img src={ fantasyrpgGameworld } alt={ "FantasyRPG Game World" } />,
							<img src={ fantasyrpgInventory } alt={ "FantasyRPG Inventory" } />,
							<img src={ fantasyrpgCombatstate } alt={ "FantasyRPG Combat State" } />
						  ] }
						  hashtags={ t('components.projects.projects.fantasyRPG.hashtags', { returnObjects: true }) as string[] }>
				<div dangerouslySetInnerHTML={{ __html: sanitizeHtml(t('components.projects.projects.fantasyRPG.html')) }} />
			</TimelineItem>
			
			<TimelineItem id={ "project-simpleq" } year={ 2023 }
						  caption={ t('components.projects.projects.simpleQ.caption') } title={t('components.projects.projects.simpleQ.title') }
						  urls={ [ { url: "https://simpleq.joshua.slaar.de", label: "Demo" }, { url: "https://github.com/SE-TINF22B2/G6-SimpleQ", label: "Repository" } ] }
						  images={ [
							<img src={ simpleqQuestion } alt={ "simpleQ Question" } />
						  ] }
						  hashtags={ t('components.projects.projects.simpleQ.hashtags', { returnObjects: true }) as string[] }>
				<div dangerouslySetInnerHTML={{ __html: sanitizeHtml(t('components.projects.projects.simpleQ.html')) }} />
			</TimelineItem>
			
			<TimelineItem id={ "project-simplechat" } year={ 2023 }
						  caption={ t('components.projects.projects.simpleChat.caption') } title={ t('components.projects.projects.simpleChat.title') }
						  urls={ [ { url: "https://simplechat.loidlnetz.de", label: "Demo" }, { url: "https://github.com/benniloidl/simplechat", label: "Repository" } ] }
						  images={ [
							<img src={ simplechat } alt={ "SimpleChat" } />
						  ] }
						  hashtags={ t('components.projects.projects.simpleChat.hashtags', { returnObjects: true }) as string[] }>
				<div dangerouslySetInnerHTML={{ __html: sanitizeHtml(t('components.projects.projects.simpleChat.html')) }} />
			</TimelineItem>
		</div>
	</Section>
}

function TimelineItem(props: {
	id: string,
	year: number | string,
	caption: string,
	title: string,
	urls?: { url: string, label: string }[],
	children?: any,
	images?: any[],
	hashtags?: string[]
}) {
	return <div className={ "timeline-item" } id={ props.id }>
		<div className={ "timeline-point" }>{ props.year }</div>
		<div className={ "timeline-content" }>			
			<div className={ "card" }>
				<h2 className={ "heading" }>{ props.title }</h2>
				<div className={ "caption-row" }>
					<p className={ "caption" }>{ props.caption }</p>
				</div>

				{ props.images && props.images.length > 0 && <div className={ "project-images" }>
					{ props.images.map((image) => image) }
				</div> }

				{ props.children }

				{ props.urls && props.urls.length > 0 && <div className={ "links" }>
					{ props.urls.map((url) => <a key={ url.url } className={ "link" } href={ url.url } rel={ "noreferrer" } target={ "_blank" }>
						<i className={ "fas fa-arrow-up-right-from-square" }/>
						{ url.label }
					</a>) }
				</div> }

				{ props.hashtags && props.hashtags.length > 0 && <p className={ "hashtags" }>
					{ props.hashtags.map((hashtag) => <span key={ hashtag }>
						#{ hashtag.trim().replaceAll(" ", "-").toLowerCase() }
					</span>) }
				</p> }
			</div>
		</div>
	</div>
}
