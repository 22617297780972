import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationEN from "./locales/en/translation.json";
import translationDE from "./locales/de/translation.json";

// internationalization resources
const resources = {
	en: {
		translation: translationEN
	},
	de: {
		translation: translationDE
	}
}

// internationalization setup
i18n.use(detector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: "en",
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
