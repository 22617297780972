import "./Education.scss";
import Section from "../components/Section";
import React from "react";
import lmg from "../images/lmg.png";
import dhbw from "../images/dhbw.png";
import xamk from "../images/xamk.png";
import { useTranslation } from "react-i18next";

export default function Education() {
	const { t } = useTranslation();

	const education: {
		name: string,
		logo: string,
		date: string,
		location: string,
		description: any[],
		links?: { reference: string, label: string }[],
		hashtags: string[]
	}[] = [
		{
			name: "Ludwig-Marum-Gymnasium",
			logo: lmg,
			date: "2014 - 2022",
			location: "Pfinztal, DE",
			description: [t('components.education.descriptions.lmg')],
			hashtags: ["abitur", "graduation", "best in class"]
		},
		{
			name: "DHBW",
			logo: dhbw,
			date: "2022 - Now",
			location: "Karlsruhe, DE",
			description: [t('components.education.descriptions.dhbw')],
			links: [{ reference: "project-simplechat", label: "SimpleChat" }, { reference: "project-simpleq", label: "simpleQ" }],
			hashtags: ["computer science", "software engineering", "web engineering"]
		},
		{
			name: "South-Eastern Finland University of Applied Sciences",
			logo: xamk,
			date: "Autumn 2024",
			location: "Mikkeli, FI",
			description: [
				t('components.education.descriptions.xamk'),
				<ul>
					<li>Design patterns and object-oriented techniques (96%)</li>
					<li>Data center environments (98%)</li>
					<li>Information and network security (97%)</li>
					<li>Big data and visualization (80%)</li>
				</ul>
			],
			links: [{ reference: "project-covid-tracker", label: "Global COVID-19 Tracker" }, { reference: "project-fantasyrpg", label: "FantasyRPG" }],
			hashtags: ["exchange semester abroad", "finland"]
		}
	];

	return <Section title={ t('components.education.title') } caption={ t('components.education.caption') }>
		<div className={ "education" }>
			{ education.map((item, index) => {
				return <div className={ "education-tile" } key={ index }>
					<div className={ "education-tile-logo fade-in" }>
						<img src={ item.logo } className={ "scroll-move" }
							 alt={ "logo of " + item.name }/>
					</div>
					<div className={ "education-tile-content blur-in" }>
						<h2 className={ "heading" }>{ item.name }</h2>
						<div className={ "caption-row" }>
							<p className={ "caption" }>{ item.date }</p>
							<p className={ "caption" }>
								<i className={ "fas fa-map-marker-alt" }/>
								{ " " }
								{ item.location }
							</p>
						</div>
						
						<div>{ item.description.map((item, index) => (
							(typeof item === "string")
								? <p key={ item }>{ item }</p>
								: item
						)) }</div>
						
						{ item.links && <div className={ "links" }>
							{ item.links.map((link) => <a key={ link.reference } className={ "link" } href={ "#" + link.reference }>
								<i className={ "fas fa-arrow-up-right-from-square" }/>
								{ link.label }
							</a>) }
                        </div> }
						
						<p className={ "hashtags" }>
							{ item.hashtags.map((hashtag) => <span key={ hashtag }>
								#{ hashtag.trim().replaceAll(" ", "-").toLowerCase() }
							</span>) }
						</p>
					</div>
				</div>
			}) }
		</div>
	</Section>
}
