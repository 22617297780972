import "./Skills.scss"
import Section from "../components/Section"
import networkSecurity from "../images/network-security.png"
import contentful from "../images/contentful-certified-professional.png"
import { useTranslation } from "react-i18next"

export default function Skills() {
	const { t } = useTranslation();

	return <Section title={ t('components.skills.title') } caption={ t('components.skills.caption') }>
		<div className={ "skills" }>			
			<div className="skills-container-lower fade-in">
				<i className="fa-brands fa-java fa-2x" title="Java"></i>
				<i className="fa-brands fa-html5 fa-2x" title="HTML"></i>
				<i className="fa-brands fa-css3 fa-2x" title="CSS"></i>
				<i className="fa-brands fa-js fa-2x" title="JavaScript"></i>
				<i className="fa-brands fa-php fa-2x" title="PHP"></i>
				<i className="fa-brands fa-swift fa-2x" title="Swift"></i>
			</div>
			
			<div className="skills-container-lower fade-in">
				<i className="fa-brands fa-git fa-2x" title="Git"></i>
				<i className="fa-brands fa-node fa-2x" title="NodeJS"></i>
				<i className="fa-brands fa-npm fa-2x" title="NPM"></i>
				<i className="fa-brands fa-react fa-2x" title="React"></i>
				<i className="fa-brands fa-linux fa-2x" title="Linux"></i>
				<i className="fas fa-server fa-2x" title="Server"></i>
				<i className="fas fa-database fa-2x" title="SQL"></i>
				<i className="fa-brands fa-stack-overflow fa-2x" title="Stack Overflow"></i>
				<i className="fa-brands fa-sass fa-2x" title="Sass"></i>
			</div>

			<div className={ "certifications blur-in" }>
				<div className={ "certification" }>
					<img src={ contentful } alt={ "Contentful Certified Professional Badge" }/>
					<a className={ "link" } href={ "https://www.credly.com/badges/611c69fb-1edd-4c56-92b0-4fff6540ae43/public_url" }
					rel={ "noreferrer" }
					target={ "_blank" }>
						<i className={ "fas fa-arrow-up-right-from-square" }/>
						{ t('components.skills.verify') }
					</a>
				</div>
				<div className={ "certification" }>
					<img src={ networkSecurity } alt={ "Network Security Badge" }/>
					<a className={ "link" } href={ "https://www.credly.com/badges/8e3eb621-e316-4da4-8755-42c3c791ca04/public_url" }
					rel={ "noreferrer" }
					target={ "_blank" }>
						<i className={ "fas fa-arrow-up-right-from-square" }/>
						{ t('components.skills.verify') }
					</a>
				</div>
			</div>
		</div>
	</Section>
}
